import TestimonialSection from 'containers/SaasModern/Testimonial';
/**
 * @typedef {import("@prismicio/client").Content.TestimonialSlice} TestimonialSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TestimonialSlice>} TestimonialProps
 * @param {TestimonialProps}
 */
const Testimonial = ({ slice }) => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="elfsight-app-c1addff3-ee59-4dda-aba6-d941435b2fa3" data-elfsight-app-lazy></div> 
    </section>
  );
};

export default Testimonial;
