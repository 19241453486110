import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

export const TestimonialSlideWrapper = styled.div`
  .glide__bullets {

    margin-top: 45px;
    text-align: center;
    @media (max-width: 575px) {
      margin-top: 30px;
    }
    .glide__bullet {
      width: 12px;
      height: 12px;
      background: #e7f1ed;
      margin: 5px;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #d8e2de;
      }
      &.glide__bullet--active {
        background: #c9cecc;
      }
    }
  }
`;

export const TestimonialItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #18001e8c;
  justify-content: center;
  border: 1px solid #fffff180;
  border-radius: ${saasModernTheme.radius[3]}px;
  padding: ${saasModernTheme.radius[5]}px;
  @media (max-width: 575px) {
    padding: 30px;
  }
`;

export const TestimonialCite = styled.cite`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: Raleway;
  flex-direction: column;
`;

export const TestimonialBlockQuote = styled.blockquote`
  text-align: center;
  font-size: ${saasModernTheme.space[4]}px;
  color: ${saasModernTheme.colors.white};
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Raleway;
`;

export const AuthorImage = styled.div`
  width: 50px;
  height: 50px;
  flex-basis: 50px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 6px 30px 0px rgba(39, 79, 117, 0.2);
  margin-right: 15px;
  position: relative;
  @media (max-width: 575px) {
    width: 40px;
    height: 40px;
    flex-basis: 40px;
  }
`;

export const AuthorName = styled.h3`
  font-family: Raleway;
  font-style: normal; /* Change from italic to normal */
  text-align: center;
  font-size: ${saasModernTheme.space[4]}px;
  color: ${saasModernTheme.colors.white};
`;

export const AuthorRole = styled.span`
  font-family: Raleway;
  font-style: normal; /* Change from italic to normal */
  text-align: center;
  font-size: ${saasModernTheme.space[3]}px;
  color: ${saasModernTheme.colors.secondaryLight};
`;

export const HeaderTemoignage = styled.header`
  text-align: center;
  margin-top: ${saasModernTheme.space[8]}px;
  margin-bottom: ${saasModernTheme.space[8]}px;
`;
